<template>
	<a-card>
		<a-spin :spinning="loading">
			<a-tabs default-active-key="1" @change="callback">
				<a-tab-pane key="1" tab="我的">
					<daily-my-error ref="myerror"></daily-my-error>
				</a-tab-pane>
				<a-tab-pane key="2" tab="团队" force-render>
					<daily-my-subordinate ref="mysubordinate"></daily-my-subordinate>
				</a-tab-pane>
			</a-tabs>
		</a-spin>
	</a-card>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import DailyMyError from './daily-myerror.vue';
import DailyMySubordinate from '../daily-my-subordinate/daily-my-subordinate';
import bus from '@/shared/bus/bus';

export default {
	name: 'att-error',
	mixins: [AppComponentBase],
	components: { DailyMyError, DailyMySubordinate },
	methods: {
		callback(key) {
			bus.$emit('calcHeight');
			switch (key) {
				case '1':
					this.$refs.myerror.getData();
					break;
				case '2':
					this.$refs.mysubordinate.getData();
					break;
			}
		},
	},
};
</script>

<style scoped></style>
